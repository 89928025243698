import React from 'react';
import useRouter from '@/ui/shared/hooks/useNextRouter';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import GetStartedButtonComponent from '@/ui/shared/components/Landing/Sections/GetStartedButton';

interface IProps {
  title?: string,
  className?: string,
}

const GetStartedButton = ({ title, className }: IProps) => {
  const { tenant } = useDataroomTenantContext();
  const { asPath: pathname } = useRouter();

  return (
    <GetStartedButtonComponent
      title={ title }
      className={ className }
      url={ dataroomUrl(tenant).getRegisterUrl(
        dataroomUrl(tenant).getUrl(pathname),
      ) }
    />
  );
};

export default GetStartedButton;
