import React from 'react';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import BookDemoButton from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import baseStyles from '../tenant17g5Styles.scss';
import styles from './learnMore.scss';

interface IProps {
  title?: string,
  description?: string,
}

const LearnMore = ({
  title = 'Ready to learn more?',
  description = `Get a quote or schedule a demo with a product specialist who can address your
                 questions and show you the platform.`,
}: IProps) => (
  <div className={ styles.learnMoreContainer }>
    <div className={ styles.learnMoreDescription }>
      <div className={ baseStyles.sectionTitle }>{ title }</div>
      <div className={ baseStyles.sectionDescription }>
        { description }
      </div>
    </div>
    <div className={ styles.learnMoreButtons }>
      <BookDemoButton className={ baseStyles.secondaryBtn } />
      <NextNavLink
        to="/get-a-quote"
        data-test="getAQuote"
      >
        <Button
          variant={ variantTypes.action }
          className={ spacesStyles.mrn }
          title="Get a Quote"
          dataTest="getAQuoteButton"
        />
      </NextNavLink>
    </div>
  </div>
);

export default LearnMore;
