import React from 'react';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import baseStyles from '../base.scss';

interface IProps {
  title?: string,
  className?: string,
  url: string,
}

const GetStartedButton = ({ title = 'Get Started', className = baseStyles.secondaryBtn, url }: IProps) => (
  <a
    suppressHydrationWarning
    href={ url }
  >
    <Button
      variant={ variantTypes.action }
      className={ className }
      type="button"
      title={ title }
      dataTest="getStartedButton"
    />
  </a>
);

export default GetStartedButton;
